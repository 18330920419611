<template>
  <section class="card">
    <div class="card-header">
      <div class="utils__title">
        <strong>Články - Typy - Reference - Přidat</strong>
      </div>
      <action-tools :router-options="routerOptions"></action-tools>
    </div>
    <div class="card-body">
      <div class="table-operations">
        <language-tab></language-tab>
      </div>
      <a-form :form="form" @submit="handleSubmit">
        <a-form-item
          label="Název"
          :validate-status="error('ale_name') ? 'error' : ''"
          :help="error('ale_name') || ''"
        >
          <a-input placeholder="Název" v-decorator="['ale_name', {rules: [{max: 60, message: 'Název nemůže být delší než 100 znaků!'}, {required: true, message: 'Název musí být vyplněn!'}]}]"/>
        </a-form-item>

        <a-form-item
          label="Nadpis tučně"
          :validate-status="error('ale_title') ? 'error' : ''"
          :help="error('ale_title') || ''"
        >
          <a-input placeholder="Nadpis tučně" v-decorator="['ale_title', {rules: [{max: 60, message: 'Nadpis tučně nemůže být delší než 60 znaků!'}]}]"/>
        </a-form-item>

        <a-form-item
          label="Nadpis kurzivou"
          :validate-status="error('ale_title_thin') ? 'error' : ''"
          :help="error('ale_title_thin') || ''"
        >
          <a-input placeholder="Nadpis kurzivou" v-decorator="['ale_title_thin', {rules: [{max: 60, message: 'Nadpis kurzivou nemůže být delší než 60 znaků!'}]}]"/>
        </a-form-item>

        <a-form-item
          label="Popisek"
          :validate-status="error('ale_description') ? 'error' : ''"
          :help="error('ale_description') || ''"
        >
          <a-input
            type="textarea"
            :autoSize="{ minRows: 4, maxRows: 10 }"
            placeholder="Popisek"
            v-decorator="['ale_description']"
          />
        </a-form-item>

        <my-form-image-item
          :image="thumbImage"
          :image-next-count="thumbImageNextCount"
          :images="thumbImages"
          :on-image-switch-change="onThumbImageSwitchChange"
          :on-next-images-click="onNextThumbImagesClick"
          :form="form"
          :required="true"
          :change-image="changeImage"
          type="numeric"
        >
          <span slot="title">
            Obrázky&nbsp;
            <a-tooltip title="Vyber si obrázek pro jednotlivé recenze.">
              <a-icon type="question-circle-o"/>
            </a-tooltip>
          </span>
        </my-form-image-item>

        <a-form-item
          label="Recenze"
          :required="true"
        >
          <a-row
            v-for="(k, index) in form.getFieldValue('items')"
            :key="index">
            <a-col :offset="1" :span="21">
              <a-form-item
                label="Fotka"
                :required="true"
              >
                <a-col :xl="4" :md="6" :sm="8" :xs="12">
                  <div v-if="thumbImage.selected[index] !== undefined" :class="[$style.item, $style.withControls]">
                    <img class="img-fluid img-size-6" v-lazy="$uploadImagePath + thumbImage.selected[index].iae_path + getImageSize(thumbImage.selected[index], 6) + '.' + thumbImage.selected[index].iae_format">
                    <div :class="$style.itemControls">
                      <span :class="$style['item-name']">{{ toImageName(thumbImage.selected[index].iae_name) }}</span>
                    </div>
                  </div>
                  <div v-else>
                    <div :class="[$style.item]">
                      <a-icon theme="twoTone" twoToneColor="#ccc" type="exclamation-circle" />
                    </div>
                    <small class="d-block ml-3">Vyber si obrázek pro tuto recenzi!</small>
                  </div>
                </a-col>
              </a-form-item>

              <a-form-item
                label="Jméno"
                :validate-status="error(`name[${index}]`) ? 'error' : ''"
                :help="error(`name[${index}]`) || ''"
              >
                <a-input v-decorator="[`name[${index}]`, { validateTrigger: ['change', 'blur'], rules: [{ required: true, message: 'Jméno musí být vyplněné!'}]}]"
                         placeholder="Jméno"
                >
                </a-input>
              </a-form-item>

              <a-form-item
                label="Pracovní pozice"
                :validate-status="error(`job[${index}]`) ? 'error' : ''"
                :help="error(`job[${index}]`) || ''"
              >
                <a-input v-decorator="[`job[${index}]`, { validateTrigger: ['change', 'blur'], rules: [{ required: true, message: 'Pracovní pozice musí být vyplněna!'}]}]"
                         placeholder="Pracovní pozice"
                >
                </a-input>
              </a-form-item>

              <a-form-item
                label="Recenze"
                :validate-status="error(`message[${index}]`) ? 'error' : ''"
                :help="error(`message[${index}]`) || ''"
              >
                <a-input v-decorator="[`message[${index}]`, { validateTrigger: ['change', 'blur'], rules: [{ required: true, message: 'Recenze musí být vyplněna!' }]}]"
                         placeholder="Recenze"
                         :autoSize="{ minRows: 4, maxRows: 10 }"
                         type="textarea"
                />
              </a-form-item>

            </a-col>
            <a-col :span="2">
              <a-icon
                v-if="form.getFieldValue('items').length > 1"
                class="dynamic-delete-button"
                type="minus-circle-o"
                :disabled="form.getFieldValue('items').length === 1"
                @click="() => removeItem(index)"
              />
            </a-col>
          </a-row>
        </a-form-item>
        <a-form-item v-bind="formItemLayout">
          <a-button
            class="m-auto d-block"
            type="dashed"
            @click="addItem"
            style="width: 200px;"
          >
            <a-icon type="plus" /> Přidat další
          </a-button>
        </a-form-item>

        <a-form-item label="Zveřejnit?">
          <a-checkbox v-decorator="['ale_is_active', { valuePropName: 'checked', initialValue: true }]"/>
        </a-form-item>

        <a-form-item label="Přidat mezeru pod článek?">
          <a-checkbox v-decorator="['gap', { valuePropName: 'checked', initialValue: true }]"/>
        </a-form-item>

        <div class="form-actions">
          <a-button html-type="submit" type="primary" :disabled="loading || verifyImages() || hasErrors(form.getFieldsError())">Přidat</a-button>
        </div>
      </a-form>
    </div>
  </section>
</template>

<script>
import LanguageTab from '@/components/MyComponents/LanguageTab'
import ActionTools from '@/components/MyComponents/ActionTools'
import { hasErrors } from '../../../../services/forms'
import imageSizeMixin from '@/components/MyComponents/Gallery/image-size-mixin'
import myFormImageItem from '@/components/MyComponents/Gallery/my-form-image-item'
import thumbImageMixin from '@/components/MyComponents/Gallery/thumb-image-mixin'
import imageMixins from '@/components/MyComponents/Gallery/image-mixins'

export default {
  components: {
    ActionTools, LanguageTab, myFormImageItem,
  },
  mixins: [imageSizeMixin, thumbImageMixin, imageMixins],
  beforeCreate() {
    this.form = this.$form.createForm(this)
    this.form.getFieldDecorator('items', { initialValue: [], preserve: true })
  },
  data() {
    return {
      hasErrors,
      loading: false,
      routerOptions: [
        {
          icon: 'bars',
          theme: 'outlined',
          to: '/article/type/ttl',
          title: 'Seznam',
        },
      ],
      item: {
        languages: [],
      },
      formItemLayout: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 20 },
        },
      },
      loaded: [],
    }
  },
  computed: {
    language: function () {
      return this.$store.getters['language/active']
    },
  },
  methods: {
    verifyImages() {
      let result = false
      this.thumbImage.selected.some(value => {
        if (value === undefined) {
          result = true
          return true
        }
      })
      return result
    },
    changeImage(image, destinationIndex) {
      const index = destinationIndex - 1
      this.thumbImage.selected[index] = image
      this.form.setFieldsValue({
        items: this.form.getFieldValue('items').concat({ image: undefined, job: undefined, name: undefined, message: undefined }),
      })
      const items = this.form.getFieldValue('items')
      items.pop()
      this.form.setFieldsValue({
        items: items,
      })
    },
    removeItem(index) {
      const items = this.form.getFieldValue('items')
      if (items.length === 1) {
        return
      }
      const names = this.form.getFieldValue('name')
      const jobs = this.form.getFieldValue('job')
      const messages = this.form.getFieldValue('message')
      items.splice(index, 1)
      names.splice(index, 1)
      jobs.splice(index, 1)
      messages.splice(index, 1)
      this.thumbImage.selected.splice(index, 1)
      this.form.setFieldsValue({
        items: items,
        name: names,
        job: jobs,
        message: messages,
      })
      this.$nextTick(() => {
        this.form.validateFields()
      })
    },
    addItem() {
      const newIndex = this.form.getFieldValue('items').length
      this.thumbImage.selected.push(undefined)
      this.form.getFieldDecorator(`name[${newIndex}]`)
      this.form.getFieldDecorator(`job[${newIndex}]`)
      this.form.getFieldDecorator(`message[${newIndex}]`)
      this.form.setFields({
        items: {
          value: this.form.getFieldValue('items').concat({
            image: undefined,
            name: undefined,
            job: undefined,
            message: undefined,
          }),
        },
        ['job[' + newIndex + ']']: { value: undefined, errors: ['Pracovní pozice musí být vyplněna!'] },
        ['name[' + newIndex + ']']: { value: undefined, errors: ['Jméno musí být vyplněné!'] },
        ['message[' + newIndex + ']']: { value: undefined, errors: ['Recenze musí být vyplněna!'] },
      })
    },
    handleSubmit(e) {
      e.preventDefault()
      this.handleChange(this.language)
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loading = true
          this.$store.dispatch('article/post', this.getData())
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    getData() {
      return {
        ate_type: 'TTL',
        languages: this.item.languages.filter(x => x.ale_name !== undefined && x.ale_name.trim().length > 0).map(x => {
          return {
            lge_id: x.lge_id,
            ale_title_thin: x.ale_title_thin !== undefined ? x.ale_title_thin : null,
            ale_content: x.ale_content,
            ale_description: x.ale_description !== undefined ? x.ale_description : null,
            ale_title: x.ale_title !== undefined ? x.ale_title : null,
            ale_name: x.ale_name !== undefined ? x.ale_name : null,
            ale_is_active: x.ale_is_active,
          }
        }),
      }
    },
    handleChange(oldLanguage) {
      const foundObj = { article: false }
      let image
      if (this.form.getFieldValue('existing') !== undefined) {
        image = this.$store.getters['image/getImageById'](this.form.getFieldValue('existing'))
      }
      const newObj = {
        article: {
          lge_id: oldLanguage,
          ale_content: {
            gap: this.form.getFieldValue('gap'),
            image: image,
            brands: [],
            items: this.form.getFieldValue('items').map((x, y) => { return { image: this.thumbImage.selected[y], job: this.form.getFieldValue('job[' + y + ']'), name: this.form.getFieldValue('name[' + y + ']'), message: this.form.getFieldValue('message[' + y + ']') } }),
          },
          ale_name: this.form.getFieldValue('ale_name'),
          ale_title_thin: this.form.getFieldValue('ale_title_thin'),
          ale_title: this.form.getFieldValue('ale_title'),
          ale_description: this.form.getFieldValue('ale_description'),
          ale_is_active: this.form.getFieldValue('ale_is_active'),
        },
      }
      this.item.languages.some((value, key) => {
        if (value.lge_id === oldLanguage) {
          this.item.languages[key] = newObj.article
          foundObj.article = true
          return true
        }
      })
      if (!foundObj.article) {
        this.item.languages.push(newObj.article)
      }
    },
    error(fieldName) {
      const { getFieldError, isFieldTouched } = this.form
      return isFieldTouched(fieldName) && getFieldError(fieldName)
    },
  },
  created() {
    if (this.form.getFieldValue('items').length === 0) {
      this.addItem()
    }
  },
  watch: {
    language(newValue, oldValue) {
      if (oldValue !== null) {
        this.handleChange(oldValue)
      }
      if (newValue !== null) {
        const foundObj = { article: this.item.languages.find(x => x.lge_id === newValue) }
        let newObj
        if (foundObj.article === undefined) {
          newObj = {
            ale_name: undefined,
            ale_is_active: true,
            ale_description: undefined,
            ale_title_thin: undefined,
            ale_title: undefined,
            gap: true,
            existing: undefined,
          }
          if (this.form.getFieldValue('items').length === 0) {
            this.addItem()
          }
          this.thumbImage.selected = []
          this.thumbImage.selectedOne = undefined
        } else {
          newObj = {
            gap: foundObj.article.ale_content.gap,
            ale_name: foundObj.article.ale_name,
            ale_is_active: foundObj.article.ale_is_active,
            ale_description: foundObj.article.ale_description,
            ale_title_thin: foundObj.article.ale_title_thin,
            ale_title: foundObj.article.ale_title,
            items: foundObj.article.ale_content.items,
            existing: foundObj.article.ale_content.image === undefined ? undefined : foundObj.article.ale_content.image.iae_id,
          }
          this.thumbImage.selectedOne = foundObj.article.ale_content.image === undefined ? undefined : foundObj.article.ale_content.image
          this.thumbImage.selected = []
          foundObj.article.ale_content.items.forEach((value, key) => {
            this.thumbImage.selected.push(value.image)
            this.form.getFieldDecorator(`job[${key}]`)
            this.form.getFieldDecorator(`name[${key}]`)
            this.form.getFieldDecorator(`message[${key}]`)
            this.form.setFields({
              ['job[' + key + ']']: { value: value.job, errors: value.job === undefined || value.job.trim().length === 0 ? ['Pracovní pozice musí být vyplněna!'] : undefined },
              ['name[' + key + ']']: { value: value.name, errors: value.name === undefined || value.name.trim().length === 0 ? ['Jméno musí být vyplněné!'] : undefined },
              ['message[' + key + ']']: { value: value.message, errors: value.message === undefined || value.message.trim().length === 0 ? ['Recenze musí být vyplněna!'] : undefined },
            })
          })
        }
        this.form.getFieldDecorator('existing')
        this.form.setFieldsValue(newObj)
        this.thumbImage.trigger = !this.thumbImage.trigger
        this.form.validateFields()
      }
    },
  },
}
</script>

<style lang="scss" module>
  @import "./style.module.scss";
</style>
